/* Common.css */
.button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
    color: #ddd;
}

.interactive:hover {
  background-color: #f0f0f0; /* Light grey, adjust to match your design */
  cursor: pointer;
}

/* For buttons specifically */
.button:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

