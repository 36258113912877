/* Navbar Styles */
.navigation {
  background-color: var(--background-color);
  color: var(--neon-blue);
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 255, 249, 0.5); /* Cyberpunk glow */
}

.navigation ul {
  list-style: none;
  display: flex;
}

.navigation li {
  margin: 0 1rem;
}

.navigation a {
  color: var(--neon-blue);
  text-decoration: none;
  font-weight: bold;
  padding: 0.5rem 1rem;
  transition: background-color var(--transition-speed), color var(--transition-speed);
}

.navigation a:hover, .navigation a.active {
  background-color: var(--button-hover-bg);
  color: var(--text-light);
}