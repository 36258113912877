@import url('https://fonts.googleapis.com/css2?family=OCR+A+Std:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

body {
  font-family: 'OCR A Std', monospace;
}


.portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.portfolio-filters {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.portfolio-items-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.portfolio-item {
  background-color: black;
  color: white;
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  position: relative;
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 0 0 1.5px var(--neon-blue); /* Adjust the width (3px) as needed */
}

.portfolio-item:hover {
  background-color: var(--background-color);
  color: black;
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.portfolio-filters button {
  background-color: rgba(var(--neon-blue-rgb), 0.8);
  color: var(--neon-pink);
  border: none;
  padding: 0.5rem 2rem;
  margin: 0 0.5rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1.5rem;
}

.portfolio-filters button:hover {
  background-color: var(--neon-pink);
  color: var(--neon-blue);
}

.portfolio-filters button.active {
  background-color: var(--neon-pink);
  color: var(--neon-blue);
}

.glitch-portfolio {
  animation: glitch-effect 7s infinite linear;
}