@import url('https://fonts.googleapis.com/css2?family=OCR+A+Std:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

:root {
  --background-color: #0a0a0a;
  --neon-blue: #00fff9;
  --neon-pink: #fb00ff;
  --text-color: #fff;
  --border-radius: 8px;
  --transition-speed: 0.3s;
  --header-font: 'Orbitron', sans-serif;
}

body {
  font-family: 'OCR A Std', monospace;
}

.about-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  padding-bottom: 100px;
}

.about-main {
  min-height: 100%;
  background: var(--background-color);
  border-radius: var(--border-radius);
  padding: 40px;
  border: 1px solid var(--neon-blue);
  position: relative;
  overflow: hidden;
}

.about-image-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid var(--neon-pink);
  margin: 20px auto;
  box-shadow: 0 0 10px var(--neon-blue), 0 0 20px var(--neon-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.about-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.collapsible {
  width: 100%;
  margin-top: 2rem;
}

.collapsible-button {
  background: var(--background-color);
  color: var(--neon-blue);
  border: 1px solid var(--neon-pink);
  padding: 0.75rem 2rem;
  border-radius: var(--border-radius);
  font-size: 1rem;
  display: block;
  text-align: center;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  transition: background-color var(--transition-speed), transform var(--transition-speed);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  outline: none;
}

.collapsible-button:hover {
  background-color: var(--button-hover-bg);
  color: var(--text-light-hover);
  transform: scale(1.05);
}

.collapsible-button::after {
  content: '▼';
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: var(--neon-pink);
}

.collapsible-content {
  max-height: 0;
  width: auto;
  overflow: hidden;
  transition: max-height var(--transition-speed) ease;
  background: var(--background-color);
  color: var(--text-color);
  border-radius: var(--border-radius);
}

.collapsible-content.open {
  max-height: 1000px;
  padding: 1rem 1.8rem;
}

/* Reverting Certifications Style */
.certifications-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  justify-content: center;
}

.certification-card {
  padding: 1rem;
  border-radius: var(--border-radius);
  background: var(--background-color);
  border: 1px solid var(--neon-blue);
  text-align: center;
}

.certification-card img {
  width: 80px;
  margin-bottom: 1rem;
}

/* Skills Section with Improved Grid */
/* Skills Section Grid */
.skills-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2x2 grid */
  gap: 1.5rem; /* Add space between the items */
  justify-content: center;
}

.skills-card {
  padding: 1rem;
  border-radius: var(--border-radius);
  background: var(--background-color);
  border: 1px solid var(--neon-blue);
  transition: transform 0.3s ease;
  position: relative;
}

.skills-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 255, 249, 0.5);
}

.skills-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  text-align: center;
}

.skills-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.skill-item {
  margin-bottom: 1rem;
}

.skill-label {
  display: flex;
  justify-content: space-between;
  color: var(--text-color);
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.skill-progress {
  background: #2d2d2d;
  border-radius: 20px;
  overflow: hidden;
  height: 12px;
  position: relative;
}

.skill-progress-bar {
  height: 100%;
  background: var(--neon-blue);
  width: 0%;
  transition: width 0.5s ease;
}

.skill-progress-bar[data-skill-level="90"] {
  width: 90%;
}

.skill-progress-bar[data-skill-level="80"] {
  width: 80%;
}

.skill-progress-bar[data-skill-level="85"] {
  width: 85%;
}

.skill-progress-bar[data-skill-level="75"] {
  width: 75%;
}

.language-badge, .music-badge, .citizenship-badge, .game-badge {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
}

/* Glitch Effect Animation */
@keyframes glitch-effect {
  0%, 90% {
    text-shadow: none;
    transform: none;
  }
  91%, 91.5% {
    text-shadow: 1rem 0 var(--neon-pink), -1rem 0 var(--neon-blue);
    transform: skew(20deg);
  }
  92%, 92.5% {
    text-shadow: -1rem 0 var(--neon-pink), 1rem 0 var(--neon-blue);
    transform: skew(-20deg);
  }
  93%, 93.5% {
    text-shadow: 0.5rem 0 var(--neon-pink), -0.5rem 0 var(--neon-blue);
    transform: skew(10deg);
  }
  94%, 94.5% {
    text-shadow: -0.5rem 0 var(--neon-pink), 0.5rem 0 var(--neon-blue);
    transform: skew(-10deg);
  }
  95%, 100% {
    text-shadow: none;
    transform: none;
  }
}

.glitch {
  animation: glitch-effect 5s infinite linear;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .certifications-container,
  .skills-container {
    grid-template-columns: 1fr;
  }
}

/* Education Section Container */
.education-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid layout */
  gap: 2rem; /* Space between cards */
  padding: 1rem 0;
}

/* Individual Education Card */
.education-card {
  background: linear-gradient(145deg, #0d0d0d, #1a1a1a); /* Gradient background for a sleek look */
  padding: 2rem;
  border-radius: 10px;
  border: 1px solid rgba(0, 255, 249, 0.5); /* Neon-style border */
  box-shadow: 0 4px 15px rgba(0, 255, 249, 0.2), 0 4px 8px rgba(0, 0, 0, 0.3); /* Enhanced shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.education-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 25px rgba(0, 255, 249, 0.5), 0 8px 16px rgba(0, 0, 0, 0.4); /* More pronounced hover effect */
}

/* Education Header */
.education-header {
  margin-bottom: 1.5rem;
}

.education-degree {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--neon-blue);
  text-shadow: 0px 0px 5px rgba(0, 255, 249, 0.8); /* Neon glow effect on degree */
  margin-bottom: 0.5rem;
}

.education-school {
  font-size: 1.1rem;
  font-weight: 500;
  color: #ccc;
  margin-top: 0.3rem;
}

/* Education Description */
.education-description {
  font-size: 0.95rem;
  line-height: 1.6;
  color: #aaa; /* Softer color for less important text */
  margin-top: 1rem;
}

/* Divider Line (Now only before the description) */
.education-card .education-description::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(0, 255, 249, 0.4); /* Subtle line */
  margin: 1rem 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .education-container {
    grid-template-columns: 1fr;
  }

  .education-card {
    padding: 1.5rem;
  }

  .education-degree {
    font-size: 1.2rem;
  }

  .education-school {
    font-size: 1rem;
  }
}
