/* PortfolioItem.css */

.portfolio-item {
  background-color: black;
  color: white;
  border: 1px solid var(--neon-blue);
  border-width: 0px;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  position: relative;
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.portfolio-item:hover {
  background-color: var(--background-color);
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.portfolio-item:hover .portfolio-title,
.portfolio-item:hover .portfolio-description {
  color: black;
}

.logo-container {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: clip;
  margin-bottom: 20px;
}

.logo-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.portfolio-title {
  font-size: 1.2rem;
  color: var(--text-color);
  text-align: center;
}

.portfolio-description {
  font-size: 1rem;
  text-align: center;
  color: var(--text-color);
  padding: 0 20px;
  margin-bottom: 20px;
}

.portfolio-item[data-company="chick-fil-a-supply"]:hover {
  background-color: #ffe6e6;
}

.portfolio-item[data-company="ups-supply-chain-solutions"]:hover {
  background-color: #fff4e6;
}

.portfolio-item[data-company="bmw-manufacturing-co.-llc"]:hover {
  background-color: #caf0f8;
}

.portfolio-item[data-company="freelance"]:hover {
  background-color: #d8f3dc;
}

.portfolio-item[data-company="costar"]:hover {
  background-color: #cce7ff; 
}






