/* App.css - Specific to App component */

.App {
  text-align: center;
}

/* For the modal */
.modal {
  z-index: 4; /* Higher than the content and navbar */
}

/* Additional App-specific styles can go below */

