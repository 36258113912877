/* Contact Container Styles */
.contact-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-size: cover;
    position: relative;
    color: #fff;
    text-align: center;
    padding: 3vh 0;
    padding-top: 10vh;
}

.contact-container .glitch {
    font-size: 4rem;
    position: relative;
    color: #0ff;
    letter-spacing: 0.05em;
    z-index: 2;
    margin-top: -1rem;
    margin-bottom: 1rem;
    transition: color 0.3s;
}

.contact-container .glitch::before,
.contact-container .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    color: #0ff;
}

.contact-container .glitch::before {
    animation: glitch-anim 3s infinite linear alternate-reverse;
    text-shadow: -2px 0 red;
    clip: rect(24px, 550px, 90px, 0);
}

.contact-container .glitch::after {
    animation: glitch-anim2 2s infinite linear alternate-reverse;
    text-shadow: 2px 0 blue;
    clip: rect(85px, 550px, 140px, 0);
}

.contact-container .success-message {
    color: #00ff00;
    font-size: 1.5rem;
    margin: 1rem 0;
    transition: color 0.3s;
}

.contact-container .error-message {
    color: red;
    font-size: 1.5rem;
    margin: 1rem 0;
    transition: color 0.3s;
}

.contact-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Input and Textarea Styles */
.contact-container input::placeholder,
.contact-container textarea::placeholder {
    color: #ccc;
    transition: color 0.3s;
}

.contact-container input:focus::placeholder,
.contact-container textarea:focus::placeholder {
    color: #007bff;
}

.contact-container input,
.contact-container textarea {
    border: none;
    border-bottom: 2px solid #fff;
    background-color: transparent;
    color: #fff;
    font-size: 1rem;
    margin: 1rem;
    padding: 0.5rem;
    width: 100%;
    outline: none;
    transition: border-color 0.3s;
}

.contact-container input:focus,
.contact-container textarea:focus {
    border-color: #007bff;
}

.contact-container .button,
.contact-container .download-button {
    background-color: #ff00ff;
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.25rem;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s, color 0.3s;
    box-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 40px #ff00ff;
    border-radius: 5px;
    margin-top: 1rem;
}

.contact-container .download-button:hover {
    background-color: #007bff;
    box-shadow: 0 0 20px #007bff, 0 0 30px #007bff, 0 0 50px #007bff;
    color: #fff;
}

/* Contact Information Styles */
.contact-info {
    margin-top: 2rem;
    color: #ff00ff; /* Blue color for links */
}

.contact-info a {
    color: #48cae4; /* Blue color for links */
    text-decoration: underline; /* Underline links */
    margin: 0 1rem; /* Add margin between links */
}

.contact-info a:hover {
    color: #00ff00; /* Green color on hover for links */
}
/* Additional Contact Information Styles */
.contact-info p a {
    font-weight: bold; /* Make links stand out */
    margin: 0 1rem; /* Add margin between links */
    transition: color 0.3s; /* Smooth color transition on hover */
}

.contact-info p a:hover {
    color: #00ff00; /* Green color on hover for links */
}
