.ReactModal__Overlay {
    background-color: transparent; /* Fully transparent overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50; /* High z-index to ensure it's on top */
}

/* Modal Content */
.ReactModal__Content {
    background: black; /* Black background for the modal content */
    color: white; /* Text color set to white for visibility */
    max-width: 500px; /* Maximum width of the modal */
    margin: auto; /* Center the modal on the page */
    box-shadow: none; /* No box-shadow for a flat design */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px; /* Padding inside the modal */
    border-radius: var(--border-radius); /* Rounded corners based on your CSS variable */
    outline: none; /* Removes the focus outline */
}

/* Header within the Modal */
.ReactModal__Content h2 {
    margin-top: 0;
    margin-bottom: 1rem; /* Space below the title */
    font-family: var(--header-font); /* Using the Orbitron font */
}

/* Paragraph within the Modal */
.ReactModal__Content p {
    line-height: 1.6; /* Spacing for readability */
    color: white; /* Ensure paragraphs are also white */
}

/* Button within the Modal */
.ReactModal__Content button {
    background-color: var(--neon-pink); /* Neon pink background */
    color: white; /* White text for better visibility */
    border: none; /* No border for a cleaner look */
    padding: 12px 25px; /* Adjust padding for better sizing */
    font-size: 16px; /* Increase font size for readability */
    font-weight: bold; /* Bold text for emphasis */
    letter-spacing: 1px; /* Slightly spaced out letters */
    text-transform: uppercase; /* Uppercase text for a more modern look */
    border-radius: var(--border-radius); /* Rounded corners from your variables */
    cursor: pointer; /* Cursor indicates clickable button */
    transition: background-color var(--transition-speed), transform var(--transition-speed);
    margin-top: 20px; /* Space above the button */
}

.ReactModal__Content button:hover {
    background-color: var(--neon-blue); /* Neon blue background on hover */
    transform: scale(1.05); /* Slightly enlarge the button on hover */
}

.ReactModal__Content--Scrollable {
    overflow-y: auto; /* Makes the content within this container scrollable */
    max-height: 600px; /* Adjust this value as needed */
}

/* Media query for responsiveness */
@media (max-width: 768px) {
    .ReactModal__Content {
        width: 90%; /* Full width with a little margin on smaller screens */
    }
}

.glitch-modal {
  animation: glitch-effect 7s infinite linear;
}

.modal-content .paragraph + .paragraph {
  margin-top: 10000px; /* Adjust the spacing as needed */
}