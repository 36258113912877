.home-container {
  /* Existing styles for home-container */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-size: cover;
  position: relative;
  color: #fff;
  text-align: center;
  padding: 3vh 0;
  padding-top: 10vh;
}

.home-container .glitch {
  /* Existing styles for glitch */
  font-size: 4rem;
  position: relative;
  color: #0ff;
  letter-spacing: 0.05em;
  z-index: 2;
  margin-top: -1rem; /* Negative value to move the text upwards */
  margin-bottom: 1rem;
}

.home-container .glitch::before,
.home-container .glitch::after {
  /* Existing styles for glitch::before and glitch::after */
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  color: #0ff;
}

.home-container .glitch::before {
  /* Existing styles for glitch::before */
  animation: glitch-anim 3s infinite linear alternate-reverse;
  text-shadow: -2px 0 red;
  clip: rect(24px, 550px, 90px, 0);
}

.home-container .glitch::after {
  /* Existing styles for glitch::after */
  animation: glitch-anim2 2s infinite linear alternate-reverse;
  text-shadow: 2px 0 blue;
  clip: rect(85px, 550px, 140px, 0);
}

.home-container .intro-text {
  /* Existing styles for intro-text */
  font-size: 1.5rem;
  margin: 1rem 0;
  z-index: 2;
}

.home-container .cta-container {
  /* Existing styles for cta-container */
  z-index: 2;
  margin-top: 1rem;
}

.home-container .cta {
  /* Existing styles for cta */
  background-color: #ff00ff;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.5s;
  box-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 40px #ff00ff;
  border-radius: 5px;
  z-index: 2;
}

.home-container .cta:hover {
  /* Existing styles for cta:hover */
  background-color: #00ffff;
  box-shadow: 0 0 20px #00ffff, 0 0 30px #00ffff, 0 0 50px #00ffff;
}

@keyframes glitch-anim {
  /* Existing keyframes for glitch-anim */
  0% {
    clip: rect(42px, 9999px, 44px, 0);
  }
  5% {
    clip: rect(78px, 9999px, 80px, 0);
  }
  100% {
    clip: rect(42px, 9999px, 44px, 0);
  }
}

@keyframes glitch-anim2 {
  /* Existing keyframes for glitch-anim2 */
  0% {
    clip: rect(20px, 9999px, 28px, 0);
  }
  5% {
    clip: rect(100px, 9999px, 108px, 0);
  }
  100% {
    clip: rect(20px, 9999px, 28px, 0);
  }
}

.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%; /* Adjust this value to control the size of the image */
  max-width: 500px; /* Optional: Use max-width to limit the size */
}
