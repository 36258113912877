@import url('https://fonts.googleapis.com/css2?family=OCR+A+Std:wght@400;700&display=swap');

:root {
  --text-color: #fff;
  --primary-color: #00fff9;
  --secondary-color: #fb00ff;
  --card-background: #1a1a1a;
  --card-shadow: 0 4px 12px rgba(0, 255, 249, 0.5);
  --border-radius: 8px;
  --transition-speed: 0.3s;
  --button-hover-bg: #ff007f;
}

html, body {
  min-height: 100%;
  background-color: black;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'OCR A Std', monospace;
  color: var(--text-color);
  font-size: 1.125rem; /* Adjust the base font size for better readability */
}

.button-primary {
  background-color: var(--neon-blue);
  color: var(--text-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition-speed);
  font-size: 1.25rem; /* Slightly larger font size for buttons */
}

.button-primary:hover {
  background-color: var(--button-hover-bg);
}

.button-secondary {
  background-color: var(--neon-pink);
  color: var(--text-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition-speed);
  font-size: 1.25rem; /* Slightly larger font size for buttons */
}

.button-secondary:hover {
  background-color: var(--button-hover-bg);
}
